<template>
  <div class="contact-members" v-if="canView">
    <page-header title="Contacter les membres" icon="fa fa-envelope" :links="getLinks()"></page-header>
    <b-row>
      <b-col cols="10">
        <div v-if="showContacts">
          <loading-gif :loading-name="contactsLoadingName"></loading-gif>
          <div v-if="!isLoading(contactsLoadingName)">
            <div v-if="contacts.length === 0" class="empty-text">
              Aucun contact
            </div>
            <div v-else ref="excelTable">
              <b><counter-label label="contact" :counter="contacts.length"></counter-label></b>
              <table class="table table-striped table-bordered">
                <tr v-for="contact of contacts" :key="contact.id" class="contact-line">
                  <td>
                    {{ contact.lastName }}
                  </td>
                  <td>
                    {{ contact.firstName }}
                  </td>
                  <td>
                    <email-link :email="contact.email"></email-link>
                  </td>
                  <td>
                    <phone-link :phone="contact.cellPhone"></phone-link>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div v-if="selectedSeparator && showEmails">
          <b><counter-label :counter="emails.length" label="e-mail"></counter-label></b>
          <emails-groups
            :emails="emails"
            :separator="selectedSeparator.value"
            :emails-number="emailsNumber"
          >
          </emails-groups>
        </div>
        <div v-if="showMobiles && selectedSeparator">
          <b><counter-label :counter="mobilePhones.length" label="numéro"></counter-label></b>
          <sms-list
            :mobile-phones="mobilePhones"
            :separator="selectedSeparator.value"
            :sms-number="smsNumber"
            :suffix="smsSuffix"
          ></sms-list>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="select-box" v-if="!showContacts">
          <b-form-group
            class="small-text"
            label="Séparateur"
            description="Les éléments seront séparés par ce caractère"
          >
            <emails-groups-separator-selector
              @change="selectedSeparator = $event.separator"
            >
            </emails-groups-separator-selector>
          </b-form-group>
        </div>
        <div class="select-box" v-if="showEmails">
          <b-form-group
            class="small-text"
            label="Taille des groupes d'e-mails"
            description="Attention! Un nombre trop grand peut générer des envois en spam"
          >
            <b-form-input type="number" v-model="emailsNumber" min="25" max="100" id="emailsNumber"></b-form-input>
          </b-form-group>
        </div>
        <div class="select-box" v-if="showMobiles">
          <b-form-group
            label="Taille des groupes de SMS" class="small-text"
          >
            <b-form-input type="number" v-model="smsNumber" id="smsNumber"></b-form-input>
          </b-form-group>
          <sms-list-operator-selector @change="smsSuffix = $event.smsSuffix" small-text>
          </sms-list-operator-selector>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import EmailsGroups from '@/components/Controls/EmailsGroups.vue'
import EmailsGroupsSeparatorSelector from '@/components/Controls/EmailsGroupsSeparatorSelector.vue'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import SmsList from '@/components/Controls/SmsList.vue'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { distinctString } from '@/utils/arrays'
import { makeIndividual } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import { cellPhoneForSms } from '@/utils/phones'
import SmsListOperatorSelector from '@/components/Controls/SmsListOperatorSelector.vue'

export default {
  name: 'ContactEntities',
  components: {
    SmsListOperatorSelector,
    PhoneLink,
    SmsList,
    EmailsGroupsSeparatorSelector,
    EmailsGroups,
    CounterLabel,
    EmailLink,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  mixins: [BackendMixin],
  data() {
    return {
      contacts: [],
      contactsLoadingName: 'contact-entities-loading',
      selectedSeparator: null,
      showEmails: false,
      showMobiles: false,
      emailsNumber: 25,
      smsNumber: 0,
      smsSuffix: '',
    }
  },
  async mounted() {
    await this.loadContacts()
  },
  watch: {
    entityIds() {
      this.loadContacts()
    },
  },
  computed: {
    entityIds() {
      return this.$route.query.ids
    },
    showContacts() {
      return !(this.showEmails || this.showMobiles)
    },
    canView() {
      return this.hasPerm('people.view_individual')
    },
    emails() {
      return distinctString(this.contacts.filter(elt => elt.email).map(elt => elt.email))
    },
    mobilePhones() {
      return distinctString(this.contacts.filter(elt => elt.cellPhone).map(elt => cellPhoneForSms(elt.cellPhone)))
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadContacts() {
      this.startLoading(this.contactsLoadingName)
      this.contacts = []
      let url = '/contact/api/contact-entities-members/'
      const backendApi = new BackendApi('post', url)
      try {
        const entities = this.entityIds.split('-').map(elt => +elt)
        const resp = await backendApi.callApi({ entities: entities, })
        this.contacts = resp.data.map(elt => makeIndividual(elt.individual))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.contactsLoadingName)
    },
    onSend() {
      if (this.contacts.length) {
        this.showEmails = true
      }
    },
    onSms() {
      if (this.contacts.length) {
        this.smsNumber = this.mobilePhones.length
        this.showMobiles = true
      }
    },
    reset() {
      this.showEmails = false
      this.showMobiles = false
    },
    onBack() {
      this.reset()
    },
    async toExcel() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'contacts'
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      if (this.showContacts) {
        return [
          {
            id: 3,
            label: 'Excel',
            callback: this.toExcel,
            icon: 'far fa-file-excel',
            cssClass: (this.contacts.length === 0) ? 'btn-disabled' : 'btn-secondary',
          },
          {
            id: 1,
            label: 'Emails',
            callback: this.onSend,
            icon: 'fa fa-send',
            cssClass: (this.contacts.length === 0) ? 'btn-disabled' : 'btn-primary',
          },
          {
            id: 2,
            label: 'SMS',
            callback: this.onSms,
            icon: 'fas fa-mobile-alt',
            cssClass: (this.contacts.length === 0) ? 'btn-disabled' : 'btn-primary',
          }
        ]
      } else {
        return [
          {
            id: 99,
            label: 'Retour',
            callback: this.onBack,
            icon: 'fa fa-chevron-left',
            cssClass: 'btn-secondary',
          }
        ]
      }
    },
  },
}
</script>

<style lang="less">
.select-box {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #c0c0c0;
}

.select-box:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.contact-line {
  padding: 5px;
  border-bottom: solid 1px #c0c0c0;
}

.contact-line:nth-of-type(even) {
  background: #fff;
}

.contact-line:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
</style>
